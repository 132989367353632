const form = document.querySelector('#feedbackForm')


const awd = RequestHandler.Form(form, {
    resetForm: true,
    onSuccess: function () {
        let success = form.querySelector('.form-success')
        success.classList.remove('d-none')
        setTimeout(function () {
            success.classList.add('d-none')
        }, 5000)
    }
})
