import delegate from 'delegate-it'

delegate(document.body, '.question__item-container', 'click', event => {
    let box = event.delegateTarget
    let btn = box.querySelector('.question__item-btn')
    let answer = box.parentElement.querySelector('.question__item-answer')

    if (answer.classList.contains('active')) {
        btn.innerHTML = '+'
        answer.classList.remove('fade')
        setTimeout(function () {
            // answer.classList.remove('fade')
            answer.classList.remove('active')
        },200)
        // answer.classList.remove('active')
    } else {
        btn.innerHTML = '-'
        answer.classList.add('active')
        setTimeout(function () {
            answer.classList.add('fade')
        },20)
    }
})